<template>
  <div class="flex items-center gap-2 pt-4">
    <p class="text-lg font-bold text-neutral-800">{{ $store.state.transifex.common.shareOn }}</p>

    <div class="flex items-center gap-4 text-primary-400">
      <a :href="fbSharedUrl" target="_blank" aria-label="Facebook">
        <span class="text-xl"><i class="fab fa-facebook-f" aria-hidden="true"></i></span>
      </a>

      <a
        :href="twSharedUrl"
        target="_blank"
        rel="noopener external"
        aria-label="Twitter"
      >
        <span class="text-xl"><i class="fab fa-twitter" aria-hidden="true"></i></span>
      </a>

      <a
        :href="vkSharedUrl"
        target="_blank"
        rel="noopener external"
        aria-label="VK"
      >
        <span class="text-xl"><i class="fab fa-vk" aria-hidden="true"></i></span>
      </a>

      <a
        :href="wbSharedUrl"
        target="_blank"
        rel="noopener external"
        aria-label="Weibo"
      >
        <span class="text-xl"><i class="fab fa-weibo" aria-hidden="true"></i></span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "share-on",
  data() {
    return {
      fbSharedUrl:
        "http://www.facebook.com/sharer.php?u=" + window.location.href,
      twSharedUrl:
        "https://twitter.com/intent/tweet?url=" +
        window.location.href +
        "&text=" +
        this.shareTitle,
      vkSharedUrl: "http://vk.com/share.php?url=" + window.location.href,
      wbSharedUrl:
        "http://service.weibo.com/share/share.php?url=" +
        window.location.href +
        "&title=" +
        this.shareTitle
    }
  },
  props: {
    shareTitle: null
  }
}
</script>

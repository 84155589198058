<template>
  <div>
    <div class="flex gap-8 pb-2">
      <p
        class="text-xl font-bold cursor-pointer"
        :class="currentTab === 'live-chapter' ? 'text-white underline underline-offset-[12px] decoration-2' : 'text-white/50'"
        @click="openProgramTab('live-chapter')"
      >
        {{ $store.state.transifex.playerChapter.program }}
      </p>
      <p
        v-if="currentLive.is_live_chat"
        class="text-xl font-bold cursor-pointer"
        :class="currentTab === 'live-chat' ? 'text-white underline underline-offset-[12px] decoration-2' : 'text-white/50'"
        @click="openProgramTab('live-chat')"
      >
        {{ $store.state.transifex.playerChapter.liveChat }}
      </p>
    </div>

    <div class="relative myScrollbar overflow-auto h-64 mt-6 bg-white lg:h-[calc(100%-56px)]">
      <div class="absolute inset-0">
        <!-- Program -->
        <div
          v-if="currentTab === 'live-chapter'"
          id="live-chapter"
          class="program-tab block text-neutral-700 px-6 py-4"
        >
          <div v-if="currentLive.live_moment">
            <p class="text-base font-bold text-primary-400 mb-1">
              {{ $store.state.transifex.playerChapter.liveMoment }}
            </p>

            <p class="text-lg font-bold mb-4">{{ currentLive.live_moment }}</p>
          </div>

          <div v-else class="">
            <div v-if="currentPerformance" class="">
              <!-- Current Performance -->
              <p class="text-base font-bold text-primary-400 mb-1">
                {{ $store.state.transifex.playerChapter.currentPerformance }}
              </p>
              
              <!-- Current performance title -->
              <p v-if="currentPerformance.title" class="text-lg font-bold mb-4">
                {{ currentPerformance.title }}
              </p>

              <!-- Current performance work movements -->
              <div
                v-for="(
                  workMovement, index
                ) in currentPerformance.works_movements"
                :key="index"
              >
                <!-- Artist -->
                <p v-if="workMovement.artists" class="text-base font-bold text-primary-400 mb-1">
                  {{ workMovement.artists }}
                </p>
                
                <!-- Work -->
                <p v-if="workMovement.musical_work" class="mb-2">
                  {{ workMovement.musical_work.title }}
                </p>

                <!-- Work's Movements -->
                <ul class="mb-3">
                  <li
                    v-for="(
                      movement, index
                    ) in workMovement.musical_work_movement"
                    :key="index"
                    class="pl-4 mb-1"
                  >
                    {{ movement.title }}
                  </li>
                </ul>
              </div>
            </div>

            <!-- Next performance -->
            <div v-if="nextPerformance" class="text-lg font-bold mb-4">
              <p class="text-base font-bold text-primary-400">
                {{ $store.state.transifex.playerChapter.nextPerformance }}
              </p>
              
              <!-- Next performance title -->
              <p v-if="nextPerformance.title" class="text-lg font-bold mb-4">
                {{ nextPerformance.title }}
              </p>
            </div>
          </div>
        </div>

        <PlayerLivechat
          v-if="
            currentLive &&
            currentLive.is_live_chat &&
            currentTab === 'live-chat'
          "
          id="live-chat"
          :liveChatCode="currentLive.live_chat_code"
          :liveChatId="currentLive.live_chat_id"
          class="program-tab block px-2"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PlayerLivechat from "@/components/player/PlayerLivechat"

export default {
  name: "player-live-chapter",
  components: {
    PlayerLivechat
  },
  props: {
    currentLive: null,
  },
  data() {
    return {
      id: "",
      currentTab: "live-chapter"
    }
  },
  computed: {
    currentPerformance() {
      if (this.currentLive.performances.length === 0) return null
      const currentPerformance = this.currentLive.performances.find(
        (el) => el.is_current_performance === true
      )
      if (currentPerformance) return currentPerformance.performance
      if (this.currentLive.performances.length > 0)
        return this.currentLive.performances[0].performance
      return null
    },
    nextPerformance() {
      if (this.currentPerformance) {
        const indexCurrentPerformance = this.currentLive.performances.findIndex(
          (el) => el.performance.id === this.currentPerformance.id
        )
        if (indexCurrentPerformance + 1 < this.currentLive.performances.length)
          return this.currentLive.performances[indexCurrentPerformance + 1]
            .performance
      }
      return null
    }
  },
  methods: {
    openProgramTab(tabName) {
      this.currentTab = tabName
    }
  }
}
</script>

<style lang="scss">
.absolute-0 {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
</style>

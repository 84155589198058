import { createStore } from 'vuex'
import {MediciPlayer, PlayerUtils} from "../js/medici_player";

export default createStore({
  state: {
    transifex: {},
    languageCode: "en",
    isHomeHeaderCarousel: true,
    replaysNumber: 0,
    playingTime: 0, // in second
    basePlayer: {},
    playerInstance: {},
    playerBaseSettings: {
      height: "100%",
      width: "100%",
      autostart: true,
    },
  },
  mutations: {
    set_player(state, playerID) {
      state.basePlayer = new MediciPlayer(playerID)
      state.playerInstance = state.basePlayer.playerInstance
    },
    start_player(state, settings = {}) {
      let {additionalSettings, movieGtmData, movieAudienceData} = settings
      const playerSettings = {
        ...state.playerBaseSettings,
        ...additionalSettings
      }
      state.basePlayer.startPlayer(playerSettings, movieGtmData, movieAudienceData)
    },
    set_isHomeHeaderCarousel(state, isHomeHeaderCarousel) {
      state.isHomeHeaderCarousel = isHomeHeaderCarousel
    },
    set_playingTime(state, timeInSecond) {
      state.playingTime = timeInSecond
    },
    set_transifex(state, transifex) {
      state.transifex = transifex
    },
    set_languageCode(state, languageCode) {
      state.languageCode = languageCode
    },
    set_replaysNumber(state, replaysNumber) {
      state.replaysNumber = replaysNumber
    },
  },
  actions: {
    observerPlayerEvents({state, commit}) {
      state.playerInstance.on("time", function (self) {
        commit("set_playingTime", parseInt(self.position))
      })
    state.playerInstance.on("firstFrame", () => {
      let position = PlayerUtils.seekTimeCodeInHash()
      if(Number.isInteger(position) && position > 0)
        state.playerInstance.seek(position)
    })
    },
  },
  modules: {
  }
})

<template>
  <div v-if="news" class="myContainer latest-news text-neutral-700 pt-7 md:pt-10">
    <div class="flex justify-between items-baseline gap-4 pb-3 lg:pb-8">
      <h3 v-html="$store.state.transifex.latestNews.title"></h3>
      <a
        :href="indexPageUrl"
        class="inline-block text-neutral-800 font-bold text-lg text-right underline hover:text-primary-500"
        v-html="$store.state.transifex.latestNews.seeAll"
        ></a
      >
    </div>

    <swiper
      :navigation="true"
      :modules="modules"
      slides-per-view="auto"
      :space-between="35"
      :breakpoints="swiperOptions.breakpoints"
      class="small-slide"
    >
      <swiper-slide v-for="(item, index) in news" :key="index">
        <a
          :href="indexPageUrl + item.slug + '/'"
          class="group relative inline-block w-full"
        >
          <img
            :src="item.image"
            :alt="item.title"
            class="object-cover w-full h-40 md:h-52 xl:h-64"
          />
          <div
            class="absolute bg-primary-400/60 inset-0 opacity-0 flex items-center justify-center transition-all ease-in-out group-hover:opacity-100"
          ></div>
        </a>

        <div class="pt-2">
          <h4>{{ item.title }}</h4>

          <p v-html="item.description" class="line-clamp-3"></p>

          <div class="mt-1 text-base font-bold text-slate-500">
            {{ item.posting_date }}
          </div>
          
          <div class="my-3 flex gap-2">
            <span v-for="(itemY, indexY) in item.categories" :key="indexY">
              <a :href="itemY.category.slug" class="tag"
                >#{{ itemY.category.title }}</a
              >
            </span>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import {appAxios} from "@/js/app_axios";
import moment from "moment"
import { Swiper, SwiperSlide } from "swiper/vue"
import { Navigation } from "swiper"
import "swiper/css"

export default {
  name: "latest-news",
  props: {
    apiRoute: null,

  },
  setup() {
    return {
      modules: [Navigation]
    }
  },
  data() {
    return {
      indexPageUrl: '/' + this.$store.state.languageCode + '/news/',
      news: null,
      locale: this.$store.state.languageCode === 'zh-hans' ? 'zh_cn' : this.$store.state.languageCode,
      swiperOptions: {
        breakpoints: {
          320: {
            slidesPerView: "auto",
            spaceBetween: 10
          },
          520: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 35
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 35
          }
        }
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  computed: {},
  async beforeCreate() {
    const { data } = await appAxios.get(this.apiRoute)
    if (data.count > 0) {
      this.news = data.results
      this.news.map((el) => {
        el.posting_date = moment(el.date).locale(this.locale).format("lll")
      })
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
//.swiper:deep() {
//
//  .swiper-slide {
//    width: 80%;
//
//    @media only screen and (min-width: 520px) {
//      width: 40% !important;
//      max-width: 400px;
//    }
//
//    @media only screen and (min-width: 1024px) {
//      width: 30% !important;
//    }
//  }
//}
</style>

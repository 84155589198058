<template>
  <div v-if="performance" class="pt-5 md:pt-8">
    <div class="myContainer">
      <div class="grid gap-6 lg:grid-cols-11">
        <div class="lg:col-span-7">
          <PlayerReplay
              :performance="performance"
          />
        </div>

        <PlayerReplayChapter
          class="relative gradient-background pt-4 pb-6 px-6 lg:col-span-4"
          :performance="performance"
        />
      </div>
      <!-- Player and chapter -->

    <BannerVote
        :currentLive=null
        :pollActive="pollActive"
    ></BannerVote>

    </div>

    <div
      v-if="performance.candidate"
      class="relative overflow-hidden bg-[#F6F6F6] mt-7 md:mt-14 section-fadein-animation"
    >
      <div class="absolute z-0 right-0 top-0 bottom-0">
        <div class="relative h-full">
          <div
            class="absolute inset-0 bg-gradient-to-t from-[#F6F6F6] sm:bg-gradient-to-r"
          ></div>
          <img
            src="https://medicitv-b.imgix.net/leeds2024/static_images/background-jury-competitors.jpg?auto=format"
            class="object-cover h-full"
            alt="details-background.avif"
          />
        </div>
      </div>
      <div
        class="myContainer relative z-10 pt-10 pb-10 mb:pb-16 lg:pt-14 lg:pb-28"
      >
        <div class="flex items-center pb-2">
          <img
            src="@/assets/details.svg"
            class="w-10 h-10 flex-shrink-0 mr-3.5"
            alt="details.svg"
          />
          <h2>{{ performance.title }}</h2>
        </div>

        <div
          v-if="performance.candidate"
          class="flex flex-col pt-7 gap-4 sm:flex-row md:gap-8 md:pt-14"
        >
          <img
            :src="performance.candidate.image"
            alt="candidate.image"
            class="object-cover w-44 h-44 md:w-64 md:h-64"
          />
          <div class="text-neutral-700">
            <p
              class="pb-3 text-sm font-bold md:text-base lg:text-2xl"
            >
              {{ performance.candidate.title }}
            </p>
            <p class="font-bold leading-5">{{ performance.candidate.age }}</p>
            <div>
              <p
                v-for="(item, index) in performance.candidate.countries"
                :key="index"
                class="leading-5 inline-block"
              >
                <span v-if="index > 0">, </span>
                {{ item.country.name }}
              </p>
            </div>
            <a
              :href="performance.candidate.url"
              class="btn-primary inline-block mt-7 md:mt-14"
              >{{ $store.state.transifex.performance.viewAll }}</a
            >
          </div>
        </div>
        <!-- Candidate -->
      </div>
    </div>
  </div>

  <!--  <pre>{{performance}}</pre>-->
</template>

<script>
import PlayerReplay from "@/components/player/PlayerReplay"
import PlayerReplayChapter from "@/components/player/PlayerReplayChapter"
import BannerVote from "@/components/BannerVote";
import {appAxios} from "@/js/app_axios";
// import ShareOn from "@/components/ShareOn"

export default {
  name: "performance-page",
  props: {
    apiRoute: null,
    pollActive: {
      type: String,
      default: null
    },
  },
  components: {
    PlayerReplay,
    PlayerReplayChapter,
    BannerVote
  },
  data() {
    return {
      pageVoteUrl: '/' + this.$store.state.languageCode + '/vote/',
      performance: null
    }
  },
  async beforeCreate() {
    const { data } = await appAxios.get(this.apiRoute)
    if (data.performance?.id) {
      this.performance = data.performance
    }
  },
  mounted() {}
}
</script>

<style lang="scss">
.bg-vote-performance-page {
  background: #f5f5f5;
}
</style>

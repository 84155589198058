<template>
  <div v-if="recommendations" class="myContainer pt-7 md:pt-10">
    <div class="flex justify-between items-baseline gap-4 pb-3 lg:pb-8">
      <h3 v-html="$store.state.transifex.medicitvMovies.title"></h3>
      <a
        href="https://www.medici.tv/en/concerts/?instrument=strings"
        target="_blank"
        class="inline-block text-neutral-800 font-bold text-lg text-right underline hover:text-primary-500"
        v-html="$store.state.transifex.medicitvMovies.seeAll"
      ></a>
    </div>
    <swiper
      :navigation="true"
      :modules="modules"
      slides-per-view="auto"
      :space-between="35"
      :breakpoints="swiperOptions.breakpoints"
      class="small-slide"
    >
      <swiper-slide v-for="(item, index) in recommendations" :key="index">
        <a
          :href="item.url"
          target="_blank"
          class="group relative inline-block w-full"
        >
          <img
            :src="item.image"
            class="object-cover w-full h-40 md:h-52 xl:h-64"
            alt="carousel.image"
          />
          <div
            class="absolute bg-primary-400/60 inset-0 opacity-0 flex items-center justify-center transition-all ease-in-out group-hover:opacity-100"
          >
            <p
              class="text-base font-bold lg:text-[22px]"
              v-html="$store.state.transifex.medicitvMovies.seeMore"
            ></p>
          </div>
        </a>

        <h4 v-if="item.title">{{ item.title }}</h4>
        <p class="text-neutral-500 text-base font-bold">
          {{ item.subtitle }}
        </p>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/vue"
import {appAxios} from "@/js/app_axios";

export default {
  name: "medicitv-movie",
  props: {
    apiRoute: null,
  },
  data() {
    return {
      recommendations: null,
      swiperOptions: {
        breakpoints: {
          320: {
            slidesPerView: "auto",
            spaceBetween: 10
          },
          520: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 35
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 35
          }
        }
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
    return {
      modules: [Navigation]
    }
  },
  computed: {},
  async beforeCreate() {
    const { data } = await appAxios.get(this.apiRoute)
    if (data.recommendations.length > 0) {
      this.recommendations = data.recommendations
    }
  },
  mounted() {}
}
</script>

<!--<style lang="scss" scoped>-->
<!--.swiper:deep() {-->
<!--  .swiper-slide {-->
<!--    width: 80%;-->
<!--    -->
<!--    @media only screen and (min-width: 520px) {-->
<!--      width: 40% !important;-->
<!--      max-width: 400px;-->
<!--    }-->

<!--    @media only screen and (min-width: 1024px) {-->
<!--      width: 30% !important;-->
<!--    }-->
<!--  }-->
<!--}-->
<!--</style>-->

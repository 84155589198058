<template>
  <div class="myContainer pt-6 md:pt-10">
    <h2 class="text-sm md:text-base lg:text-2xl normal-case">
      {{ $store.state.transifex.common.resultByRound }}
    </h2>

    <v-select
      :multiple="true"
      :placeholder="$store.state.transifex.common.round"
      :modelValue="selectedRound"
      :options="rounds"
      label="title"
      :reduce="(round) => round.id"
      :filterable="true"
      @update:modelValue="updateRoundFilter"
      class="pt-4 sm:w-72 sm:col-span-2 md:col-span-1"
    >
      <template #open-indicator="{ attributes }">
        <span v-bind="attributes" class="mr-3">
          <img
            src="@/assets/vselect-arrow.svg"
            class="w-4 h-4 flex-shrink-0 cursor-auto"
            alt="vselect-arrow.svg"
          />
        </span>
      </template>
    </v-select>

    <div
      class="grid grid-cols-2 gap-6 pt-7 md:pt-11 md:grid-cols-3 md:gap-12 lg:grid-cols-5"
    >
      <div v-for="(result, index) in searchResult" :key="index">
        <a
          :href="result.performance.candidate.url"
          class="group relative inline-block w-full"
        >
          <img
            :src="result.performance.candidate.image"
            class="object-cover w-36 h-36 sm:w-40 sm:h-40 md:w-44 md:h-44 lg:w-48 lg:h-48 xl:w-52 xl:h-52"
            alt="carousel.image"
          />
          <div
            class="absolute bg-primary-400/60 inset-0 opacity-0 flex items-center justify-center transition-all ease-in-out group-hover:opacity-100"
          ></div>
        </a>

        <p class="pb-2 font-bold text-sm md:text-base lg:text-2xl normal-case">
          {{ result.performance.candidate.title }}
        </p>
        <p v-if="result.performance.candidate.age" class="font-bold">
          Age: {{ result.performance.candidate.age }}
        </p>
        <div v-if="result.performance.candidate.countries.length > 0">
          <p
            v-for="(itemC, index) in result.performance.candidate.countries"
            :key="index"
            class="leading-5 inline-block"
          >
            {{ itemC.country.name
            }}<span
              v-if="
                index >= 0 &&
                index != result.performance.candidate.countries.length - 1
              "
              >,&nbsp;</span
            >
          </p>
        </div>
      </div>
    </div>
    <!--    <pre>{{rounds}}</pre>-->
    <!--    <pre>{{candidates}}</pre>-->
  </div>
</template>

<script>
import {appAxios} from "@/js/app_axios";
import vSelect from "vue-select"
import { appUtils } from "@/js/utils"
export default {
  name: "result-by-round",
  components: {
    vSelect
  },
  data() {
    return {
      rounds: [],
      lastRound: null,
      searchResult: [],
      selectedRound: []
    }
  },
  props: {
    resultByRoundRoute: null,
    resultPageFilter: null,
  },
  async beforeCreate() {
    try {
      const result = await appAxios.get(this.resultPageFilter)
      if (result.data.round.length > 0) {
        this.rounds = result.data.round
        this.lastRound = this.rounds[this.rounds.length - 1]
      }
    } catch (e) {
      console.error(e)
    }

    try {
      let resultByRoundRoute = this.resultByRoundRoute
      if (this.lastRound) {
        this.selectedRound.push(this.lastRound.id)
        resultByRoundRoute =
          resultByRoundRoute + "&" + "round=" + this.lastRound.id
      }
      const { data } = await appAxios.get(resultByRoundRoute)
      if (data.count > 0) {
        this.searchResult = data.results
      }
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    async updateRoundFilter(value) {
      this.selectedRound = value
      await this.updateFilter(value, "round")
    },
    async updateFilter(selectedFilter, queryParam) {
      appUtils.updateFilter(selectedFilter, queryParam)
      let searchParams = new URLSearchParams(window.location.search)
      let searchAPIRoute =
        this.resultByRoundRoute + "&" + searchParams.toString()
      const { data } = await appAxios.get(searchAPIRoute)
      this.searchResult = data.results
    }
  }
}
</script>

<style lang="scss"></style>

<template>
  <div
    class="relative bg-no-repeat bg-cover bg-center mt-8 lg:mt-14 section-fadein-animation"
    :style="{
      backgroundImage: `url('https://medicitv-b.imgix.net/leeds2024/static_images/newsletter-banner-5.jpg?auto=format')`
    }"
  >
    <div
      class="absolute inset-0 bg-overlay-500"
    ></div>
    <div
      class="relative myContainer mx-auto px-8 py-10 flex flex-col gap-4 xl:py-20 xl:pl-10"
    >
      <h2
        class="text-white normal-case lg:w-3/4 xl:w-7/12"
        v-html="$store.state.transifex.newsletter.title"
      ></h2>

      <p
        class="text-base text-white "
        v-html="$store.state.transifex.newsletter.description"
      ></p>

      <form v-on:submit.prevent="onSubmit" class="flex">
        <input
          type="email"
          :placeholder="$store.state.transifex.newsletter.placeholder"
          v-model="newsletterEmail"
          required
          class="text-lg placeholder:text-neutral-400 px-4 py-3 w-52 md:w-[430px]"
        />

        <button
          type="submit"
          class="btn-primary whitespace-nowrap"
          v-html="$store.state.transifex.newsletter.btn"
        ></button>
      </form>

      <div v-if="successfulMessage" class="ml-4 mt-2 font-bold">
        {{ successfulMessage }}
      </div>

      <div v-if="errorMessage" class="ml-4 mt-2 font-bold">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import { formatNewsletterSchema } from "@/js/newsletter_schema"
import { GTMManager } from "@/js/gtm_manager"
import {appAxios} from "@/js/app_axios";

export default {
  name: "newsletter-section",
  data() {
    return {
      newsletterEmail: "",
      successfulMessage: null,
      errorMessage: null,
      gtm: new GTMManager()
    }
  },
  methods: {
    async onSubmit() {
      const NewsletterApi = "https://api.medici.tv/satie/newsletter/"
      let postData = formatNewsletterSchema(this.newsletterEmail, true, this.$store.state.languageCode)
      try {
        await appAxios.post(NewsletterApi, postData)
        localStorage.setItem("Leeds2024MedicitvNewsLetter", "True")
        this.successfulMessage =
          "You've successfully subscribed to our newsletter!"
        this.gtm.pushGTM("newsletter_success")
      } catch (error) {
        this.errorMessage =
          "Unexpected error occurred, Please try again later !"
        this.gtm.pushGTM("newsletter_error")
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss"></style>

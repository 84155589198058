<template>
  <div class="mt-4">
    <div v-if="poll">
      <p v-if="poll.is_open_soon" class="font-bold text-primary-500">
        {{ $store.state.transifex.vote.beginAt }} {{ poll.start_date }} - {{$store.state.transifex.common.timezone}} {{timeZone}}
        {{ $store.state.transifex.vote.endAt }} {{ poll.end_date }} - {{$store.state.transifex.common.timezone}} {{timeZone}}
      </p>
      <p v-if="poll.is_active" class="font-bold text-primary-500">
        {{ $store.state.transifex.vote.voteUntil }} {{ poll.end_date }} - {{$store.state.transifex.common.timezone}} {{timeZone}}
      </p>
      <p v-if="poll.is_closed" class="font-bold text-primary-500">
        {{ $store.state.transifex.vote.voteEnded }} - {{$store.state.transifex.common.timezone}} {{timeZone}}
      </p>
      <div v-if="votedCandidate" class="pt-6 font-bold">
        <p>{{ $store.state.transifex.vote.votedFor }} {{ votedCandidate.title }}</p>
        <p>{{ $store.state.transifex.vote.thanks }}</p>
        <p>
          {{ $store.state.transifex.vote.nextVoting }} <span id="data-countdown-date"></span>
        </p>
      </div>
      <div
        class="grid grid-cols-2 gap-6 pt-7 md:pt-11 md:grid-cols-3 md:gap-12"
      >
        <div v-for="(candidate, index) in poll.candidates" :key="index">
          <div class="group relative inline-block w-full">
            <img
              :src="getImage(candidate)"
              class="object-cover w-full h-44 md:h-60 xl:h-88"
              :alt="candidate.title"
            />
            <div
              @click="showVoteModal(candidate)"
              class="absolute cursor-pointer items-center justify-center inset-0 opacity-0 flex items-end transition-all ease-in-out bg-primary-400/60 group-hover:opacity-100"
            >
              <div
                class="mt-10 font-bold text-sm md:text-base text-white lg:text-2xl"
              >
                {{ $store.state.transifex.vote.btn }} {{ candidate.title }}
              </div>
            </div>
          </div>
          <div class="mt-1">
            <p class="font-bold text-sm md:text-base lg:text-2xl">
              {{ candidate.title }}
            </p>
          </div>
          <!--          <button type="button" class="btn-primary py-1.5" @click="showVoteModal(candidate)" :disabled="!!votedCandidate&&poll.is_active">{{$store.state.transifex.vote.btn}}</button>-->
        </div>
      </div>
    </div>
    <VoteModal
      :candidate="selectedCandidate"
      :poll="poll"
      :apiRegisterVote="apiRegisterVote"
    ></VoteModal>
  </div>
</template>

<script>
import moment from "moment"
import timezone from "moment-timezone"
import {appAxios} from "@/js/app_axios";
import VoteModal from "@/components/VoteModal"
import { appUtils } from "@/js/utils"

export default {
  name: "vote-page",
  props: {
    apiRoute: null,
    apiRegisterVote: null
  },
  components: {
    VoteModal
  },
  data() {
    return {
      poll: null,
      modal: null,
      selectedCandidate: null,
      votedCandidate: null,
      locale: this.$store.state.languageCode === 'zh-hans' ? 'zh_cn' : this.$store.state.languageCode,
      timeZone: timezone.tz.guess()
    }
  },
  async beforeCreate() {
    const { data } = await appAxios.get(this.apiRoute)
    if (data.count > 0) {
      this.poll = data.results[0]
      this.poll.start_date = moment(this.poll.start_date).locale(this.locale).format("LLL")
      this.poll.end_date = moment(this.poll.end_date).locale(this.locale).format("LLL")
      this.poll.candidates = []
      if (this.poll.poll_candidates.length > 0) {
        // Transform poll_candidate to candidate
        this.poll.poll_candidates.map((el) => {
          this.poll.candidates.push(el.candidate)
        })
      }
    }
  },
  mounted() {
    this.modal = document.getElementById("modalVote")
    this.votedCandidate = this.getVotedCandidate()
    if (this.votedCandidate) {
      let available_date = moment(this.votedCandidate.voting_date).add(1, "d")
      let isAfterNow = available_date.isAfter(moment.tz().format())
      if (isAfterNow) {
        appUtils.countdown(
          available_date,
          "data-countdown-date",
          this.$store.state.transifex,
          this.callbackAt0,
          true
        )
      } else {
        this.votedCandidate = null
      }
    }
  },
  methods: {
    callbackAt0() {
      console.log("vote available now !")
      location.reload()
    },
    showVoteModal(candidate) {
      this.selectedCandidate = candidate
      this.modal.style.display = "block"
    },
    getVotedCandidate() {
      let candidate = window.localStorage.getItem("voted_leeds_2024")
      if (candidate) return JSON.parse(candidate)
      return null
    },
    getImage(item) {
      if (document.body.clientWidth < 525) {
        return item.image
      }
      return item.image_16_9
    }
  }
}
</script>

<style lang="scss"></style>

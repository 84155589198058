<template>
  <div>
    <div>
      <div :class="{ 'md:py-12 bg-white py-8': currentLive || pollActive }">
        <div class="myContainer">
          <div v-if="currentLive" class="grid gap-6 lg:grid-cols-11">
            <!-- Player container -->
            <div class="lg:col-span-7">
              <PlayerLive
                  :currentLive="currentLive"
              />
            </div>

            <!-- Program / Live chat container -->
            <PlayerLiveChapter
              class="relative gradient-background pt-4 pb-6 px-6 lg:col-span-4"
              :currentLive="currentLive"
            />
          </div>

          <BannerVote
            :currentLive="currentLive"
            :pollActive="pollActive"
          ></BannerVote>
        </div>
      </div>
    </div>

    <LiveInforModal :currentLive="currentLive" />
  </div>
</template>

<script>
import PlayerLive from "@/components/player/PlayerLive"
import PlayerLiveChapter from "@/components/player/PlayerLiveChapter"
import LiveInforModal from "@/components/LiveInforModal"
import BannerVote from "@/components/BannerVote";
import { brahmsWebSocket } from "@/js/brahms_websocket"
import {appAxios} from "@/js/app_axios";

export default {
  name: "player-live-wrapper",
  components: {
    PlayerLive,
    PlayerLiveChapter,
    LiveInforModal,
    BannerVote
  },
  data() {
    return {
      currentLive: null,
      pageVoteUrl: '/' + this.$store.state.languageCode + '/vote/',
    }
  },
  props: {
    apiRoute: null,
    webSocketUrl: null,
    siteName: null,
    pollActive: {
      type: String,
      default: null
    }
  },
  async mounted() {
    await this.getCurrentLive()
    const subscribeAction = { action: "subscribe", payload: "brahms.live" }
    brahmsWebSocket(
      this.webSocketUrl,
      subscribeAction,
      this.onMessageWSCallBack
    )
  },
  methods: {
    onMessageWSCallBack(event) {
      const updatedLiveId = JSON.parse(event.data).live_id
      const site = JSON.parse(event.data).site
      // Get a random timeout between 10000 -> 15000 miliseconds
      // For limit the request comme all in same time
      let timeout = Math.floor(((Math.random() * 10 + 1) / 2 + 10) * 1000)
      if (site === this.siteName) {
        if (this.currentLive) {
          if (this.currentLive.id === updatedLiveId)
            setTimeout(async () => await this.getCurrentLive(), timeout)
        } else {
          // No currentLive -> call api /lives
          setTimeout(async () => await this.getCurrentLive(), timeout)
        }
      }
    },
    async getCurrentLive() {
      const { data } = await appAxios.get(this.apiRoute)
      if (data.count > 0) {
        this.currentLive = data.results[0]
        this.$store.commit("set_isHomeHeaderCarousel", false)
      } else {
        this.currentLive = null
        this.$store.commit("set_isHomeHeaderCarousel", true)
      }
    }
  }
}
</script>

<style lang="scss">
.player-chapter {
  position: absolute;
  left: 0;
  right: 0;
}
</style>

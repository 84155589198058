import { PlayerCollector } from "./medici_player_collector"

/** ********************** MediciPlayer ************************/
class MediciPlayer {
  constructor(playerId) {
    this.playerID = playerId
    this.playerInstance = window.jwplayer(this.playerID)
    this.playerCollector = new PlayerCollector(this.playerInstance)
  }

  startPlayer(playerSetting, movieGtmData = null, movieAudienceData = null) {
      try {
          this.playerInstance.setup(playerSetting)
          if (process.env.NODE_ENV === "production" && !!movieGtmData && !!movieAudienceData)
              this.playerCollector.setupStatsCollector(movieGtmData, movieAudienceData)
      } catch (e) {
          console.error(e)
      }
  }

  // startPlayerForStaticMovie(playerSetting, trackingData = {}) {
  //   try {
  //     this.playerInstance.setup(playerSetting)
  //     if (process.env.NODE_ENV === "production" && !!movieGtmData && !!movieAudienceData)
  //       this.playerCollector.setupStatsCollector(trackingData)
  //   } catch (e) {
  //     console.error(e)
  //   }
  // }

  toggleVolume() {
    this.isMute()
      ? this.playerInstance.setMute(false)
      : this.playerInstance.setMute(true)
  }

  isMute() {
    return this.playerInstance.getMute()
  }
}

class JwplayerViewManager {

	addNewsletterOverlay(player){
		/**
		 * Show overlay with the newsletter on player after a minute *
		 * **/
		player.on("firstFrame", (() => {
			setTimeout(() => {
					if(!localStorage.getItem("Leeds2024MedicitvNewsLetter")){
                        if (player.getState() !== 'paused') player.pause();
						if (player.getFullscreen()) player.setFullscreen(false);
                        setTimeout(() => {
                            let player_brahms_class = document.getElementById('player-brahms').classList;
                            if (player_brahms_class) player_brahms_class.add('hide-floating-player');
                            let newsletter_overlay = document.getElementById('newsletter-overlay');
                            if (newsletter_overlay) newsletter_overlay.style.display = "flex";
                        }, 200)
					}
				}
				, 2000);
		}));
	}

	hideNewsletterOverlay(player) {
        let newsletter_overlay = document.getElementById('newsletter-overlay');
        if (newsletter_overlay) newsletter_overlay.style.display = "none";
        let player_brahms_class = document.getElementById('player-brahms').classList;
        if (player_brahms_class) player_brahms_class.remove('hide-floating-player');
        player.play();
    }

	hideNewsletterOverlaySubmit(player){
		localStorage.setItem("Leeds2024MedicitvNewsLetter", "True")
		this.hideNewsletterOverlay(player);
	}

	addTagLiveOnReady(player) {
        player.on('ready', (() => {
            let video_tag_live = document.getElementById('video-tag-live')
            if (video_tag_live) {
                let video_tag_live_clone = video_tag_live.cloneNode(true)
                let jw_controls = document.getElementsByClassName('jw-controls-backdrop')[0]
                if (jw_controls) {
                    jw_controls.insertBefore(video_tag_live_clone, null)
                    video_tag_live.style.display = "inline-block"
                }
            }
        }));
    }
    //
	// addLogo() {
	// 	this.player.on('ready', (() => {
	// 		setTimeout(function () {
	// 			$('.logo-player-replay-leeds').clone().insertBefore('.jw-controls');
	// 			$(".jwplayer").find(".logo-player-replay-leeds").css("display", "inline-block");
	// 		}, 500);
	// 	}));
	// }
    //
	// addLogoPlayerLive() {
	// 	this.player.on('ready', (() => {
	// 		setTimeout(function () {
	// 			$('.logo-player-leeds').clone().insertBefore('.jw-controls');
	// 			$(".jwplayer").find(".logo-player-leeds").css("display", "inline-block");
	// 		}, 500);
	// 	}));
	// }
    //
	// onTagPlayerLiveChange() {
	// 	// Remove existed Tag
	// 	$(".jwplayer").find(".tag-overlay-video").remove();
    //
	// 	// Timeout for waiting Vue DOM re-rendering after that we clone and insertBefore
	// 	setTimeout(function () {
	// 		$('.tag-overlay-video').clone().insertBefore('.jw-controls');
	// 		$(".jwplayer").find(".tag-overlay-video").css("display", "inline-block");
	// 	}, 300);
	// }
    //
	// addInfosBtn(){
	// 	this.player.on('ready', (() => {
	// 		$('.btn-more-info-video').insertBefore('.jw-controls');
    //
	// 		$('.btn-more-info-video button').on('click touch', function() {
	// 			$('.btn-more-info-video').toggleClass('active');
	// 		})
	// 	}));
	// }
    //
	// addWatchingMoreBtn(){
	// 	this.player.on('ready', (() => {
	// 		$('.btn-watching-more').insertBefore('.jw-icon-settings');
	// 		$('.btn-watching-more').css("display", "inline");
	// 	}));
	// }
}


const PlayerUtils = {
  addTCStartTCEndForVideoFile(movie) {
    if (movie.video.is_full_video) {
      const movieFileURL = new URL(movie.video.video_url)
      const search_params = movieFileURL.searchParams

      // set() will change if exist if not add new query string
      if (movie.tc_start_home)
        // 1st chapter tc_start
        search_params.set("start", movie.tc_start_home)
      if (movie.tc_end_home)
        // last chapter tc_end
        search_params.set("end", movie.tc_end_home)

      // change the search property of the main url
      movieFileURL.search = search_params.toString()

      // Return changed url
      return movieFileURL.toString()
    }
    return movie.video.video_url
  },
  formatPlayListLive(live) {
    const playList = []
    playList.push({
      // file: this.addTCStartTCEndForVideoFile(movie),
      file: live.stream.url,
      // isPreroll: false,
      // image: movie.picture
    }) // add movie file

    return playList
  },
  formatPlayListRePlay(performance) {
    const playList = []
    playList.push({
      // file: this.addTCStartTCEndForVideoFile(movie),
      file: performance.video_file.url,
      // isPreroll: false,
      image: performance.video_image
    }) // add movie file

    return playList
  },
  extractAudienceData() {
    return {
      slug: 'test',
      title: 'test',
      category: 'test',
    }
  },
  seekTimeCodeInHash() {
    /** Return time code in hash **/
    const hash = window.location.hash.substring(1)
    if (hash) {
      const parseHash = hash
        .split("&")
        .map((v) => v.split("="))
        .reduce((pre, [key, value]) => ({ ...pre, [key]: value }), {})
      let timecode = parseHash?.timecode
      if (timecode) return parseInt(timecode)
    }
    return 0
  }
}

export { MediciPlayer, PlayerUtils, JwplayerViewManager }

<template>
  <div v-if="currentLive" id="modalLiveInfor" class="modal-live-info" ref="modalLiveInfor">
    <span class="close-modal" @click="$refs.modalLiveInfor.style.display = 'none'">
      <svg class="w-4 h-4 flex-shrink-0" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.41309" y="0.266846" width="42.478" height="1.99897" transform="rotate(45 1.41309 0.266846)" fill="#FFFFFF"/>
        <rect x="0.482422" y="30.0366" width="42.478" height="1.99897" transform="rotate(-45 0.482422 30.0366)" fill="#FFFFFF"/>
      </svg>
    </span>
    <div class="px-2 md:px-4 pt-2 md:pt-3">
      <div class="text-base font-bold uppercase">
        <i class="fas fa-bolt"></i> {{ $store.state.transifex.common.liveUpdate }}
      </div>
      <div class="text-sm md:pt-1">
        {{ currentLive.live_update }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "live-info-modal",
  props: {
    currentLive: null
  }
}
</script>

<style lang="scss">
.modal-live-info {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  background-color: #e94047;
  z-index: 100;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 0;
  width: 585px;
  height: 78px;
  box-shadow: 0 0 12px rgba(77, 77, 77, 0.7);
  border-radius: 9px;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 0.8s;
  animation-name: animatebottom;
  animation-duration: 0.8s;
}

.close-modal {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .modal-live-info {
    width: 80%;
  }
}

@media only screen and (max-width: 425px) {
  .modal-live-info {
    width: 90%;
  }
}

/* Add Animation */
@-webkit-keyframes animatebottom {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 10px;
    opacity: 1;
  }
}

@keyframes animatebottom {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 10px;
    opacity: 1;
  }
}

.modal-header {
  padding: 2px 16px;
}

.modal-body {
  padding: 2px 16px;
}

.modal-footer {
  padding: 2px 16px;
}
</style>

<template>
  <div v-if="nextLive">
    <div>
      <span v-if="!isEndOfCountdown">{{
        $store.state.transifex.countdown.nextDiffusion
      }}</span>
      <span v-if="!isEndOfCountdown" id="data-countdown-date"></span>
    </div>

    <div v-if="isEndOfCountdown">
      <div>{{ $store.state.transifex.countdown.liveComingSoon }}</div>

      <div>
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { appUtils } from "@/js/utils"

export default {
  name: "countdown-embed",
  components: {},
  data() {
    return {
      countdownInterval: null,
      isEndOfCountdown: false
    }
  },
  props: {
    nextLive: null,
  },
  mounted() {
    appUtils.countdown(
      this.nextLive.start_date,
      "data-countdown-date",
      this.$store.state.transifex,
      this.callbackAt0
    )
  },
  methods: {
    callbackAt0() {
      this.isEndOfCountdown = true
    }
  }
}
</script>

<style lang="scss">
.lds-ring {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 29px;
  height: 29px;
  margin: 8px;
  border: 3px solid red;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: red transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

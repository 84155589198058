<template>
  <div
    v-if="banner"
    class="relative flex items-center h-84 mt-8 lg:mt-14 xl:h-102 xl:justify-end section-fadein-animation"
    :style="{ backgroundImage: 'url(' + banner.image + ')' }"
  >
    <div class="absolute inset-0 bg-overlay-500"></div>
    <div
      class="relative myContainer px-8 flex flex-col gap-6 xl:gap-8 xl:pr-10 xl:mx-auto"
    >
      <img
        src="@/assets/medici.svg"
        width="224"
        height="44"
        class="w-48 md:w-50"
        alt="medici.svg"
      />
      <h2
        class="text-white xl:w-[58rem]"
        v-html="banner.title"
      ></h2>
      <p
        class="-mt-2 text-base sm:w-3/4 xl:w-[43rem]"
        v-html="banner.description"
      ></p>
      <div class="inline-flex">
        <a
          :href="banner.link"
          target="_blank"
          class="btn-primary"
          v-html="banner.btn_text"
        ></a>
      </div>
    </div>
  </div>
</template>

<script>

import {appAxios} from "@/js/app_axios";

export default {
  name: "banner-section",
  props: {
    apiRoute: null
  },
  data() {
    return {
      banner: null
    }
  },
  async beforeCreate() {
    const windowWidth = window.innerWidth
    const { data } = await appAxios.get(this.apiRoute)
    if (data.banner) {
      this.banner = data.banner
      windowWidth > 576
        ? (this.banner.image = this.banner.image_desktop)
        : (this.banner.image = this.banner.image_mobile)
    }
  }
}
</script>

<style lang="scss"></style>

<template>
  <div>
    <swiper
      :autoplay="{ delay: 5000 }"
      :pagination="{ clickable: true }"
      :modules="modules"
      class="mySwiper"
      :slides-per-view="1"
      :space-between="50"
      effect="fade"
    >
      <swiper-slide v-for="(item, index) in carouselItems" :key="index">
        <div
          class="relative h-135 flex items-center bg-no-repeat bg-cover bg-center"
          :style="{ backgroundImage: 'url(' + item.image + ')' }"
        >
          <div class="absolute z-0 inset-0 bg-black opacity-50"></div>

          <div class="relative myContainer flex flex-col h-[375px] z-10 -mt-12">
            <div class="max-w-md">
              <h2
                v-if="item.title"
                class="text-white text-4xl font-bold pb-6"
                v-html="item.title"
              ></h2>
              
              <p class="text-lg pb-6">{{ item.description }}</p>
            </div>

            <div v-if="item.open_in_new_tab" class="mt-8">
              <a
                :href="item.link"
                target="_blank"
                class="btn-primary mt-auto text-1-rem-mobile"
                >{{ item.btn_text }}</a
              >
            </div>
            <div v-else class="mt-8">
              <a
                :href="item.link"
                class="btn-primary mt-auto text-1-rem-mobile"
                >{{ item.btn_text }}</a
              >
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
  <!--  <pre>{{carouselItems}}</pre>-->
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue"
import { Autoplay, Navigation, Pagination, EffectFade } from "swiper"
import "swiper/scss"
import "swiper/scss/effect-fade"

export default {
  name: "carousel-header",
  props: {
    carouselItems: null
  },
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
    return {
      modules: [Navigation, Pagination, Autoplay, EffectFade]
    }
  },
  computed: {},
  async beforeCreate() {},
  mounted() {}
}
</script>

<style lang="scss" scoped>
.swiper:deep() {
  .swiper-pagination {
    position: absolute;
    bottom: 40px;
    right: 7%;
    z-index: 10;

    @media only screen and (max-width: 767px) {
      display: none;
    }

    .swiper-pagination-bullet {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin: 0 8px;
      border: 1px solid white;
      border-radius: 100%;
      cursor: pointer;

      &.swiper-pagination-bullet-active {
        background-color: white;
      }
    }
  }
}
</style>

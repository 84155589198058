import axios from "axios";
class MEvent {
  constructor() {

    this._post = function (type, data) {
      data["timestamp"] = new Date().toISOString()

      let context = (data["context"] = {
        application: "brahms"
      })

      let hostname = window.location.hostname
      data["hostname"] = hostname // Use data["hostname"] for tracking as site medici.tv
      context.hostname = hostname
      context.path = window.location.pathname

      axios
        .post("https://events.medicitv.fr/" + type, data)
        .catch(function (error) {
          // handle error
          console.log(
            "mEvent failed while sending " + type + " event : " + error
          )
        })
    }
  }

  error(code, message, cause, details = "") {
    const data = {
      type: "error",
      error: {
        code,
        ...(message && { message }),
        ...(cause && { cause }),
        details
      }
    }
    this._post("error", data)
  }

  stats(code, stats) {
    let data = {
      type: "stats",
      stats: Object.assign({ code: code }, stats)
    }
    this._post("stats", data)
  }

  audience(code, stats) {
    let data = {
      type: "audience",
      audience: Object.assign({ code: code }, stats)
    }
    this._post("audience", data)
  }
}

export { MEvent }
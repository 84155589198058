<template>
  <div></div>
</template>

<script>
  export default {
    name: "update-store",
    props: {
      transifex: null,
      languageCode: {
        type: String,
        default: "en"
      }
    },
    async beforeCreate() {
      this.$store.commit("set_transifex", this.transifex)
      this.$store.commit("set_languageCode", this.languageCode)
    }
  }
</script>

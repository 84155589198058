<template>
  <div v-if="finalResult" class="pt-4">
    <div v-if="!finalResult.is_active" class="myContainer">
      {{ $store.state.transifex.result.beginAt }} {{ finalResult.date }}
    </div>
    <div v-else class="bg-[#F7F7F7]">
      <div class="myContainer pt-6">
        <h2 class="font-bold text-sm md:text-base lg:text-2xl normal-case">
          {{ $store.state.transifex.result.winners }}
        </h2>
      </div>
      <div class="myContainer pb-7 md:pb-12 lg:flex">
        <div
          class="lg:w-3/4 grid grid-cols-2 gap-4 pt-7 md:pt-11 md:grid-cols-3"
        >
          <div v-for="(prize_candidate, index) in mainPrizes" :key="index">
            <a :href="prize_candidate.candidate.url" class="block">
              <img
                :src="prize_candidate.candidate.image"
                class="object-cover w-36 h-36 sm:w-40 sm:h-40 md:w-44 md:h-44 lg:w-52 lg:h-52 xl:w-64 xl:h-64"
                alt="candidate.image"
              />
              <p
                class="pt-2 font-bold text-sm md:text-base lg:text-2xl lg:pb-2 normal-case text-primary-500"
              >
                {{ prize_candidate.title.title }}
              </p>
              <p class="font-bold text-sm md:text-base lg:text-2xl normal-case">
                {{ prize_candidate.candidate.title }}
              </p>
              <p v-if="prize_candidate.candidate.age" class="font-bold">
                {{ prize_candidate.candidate.age }}
                {{ $store.state.transifex.common.yearsOld }}
              </p>
              <div v-if="prize_candidate.candidate.countries.length > 0">
                <p
                  v-for="(itemC, index) in prize_candidate.candidate.countries"
                  :key="index"
                  class="leading-5 inline-block"
                >
                  {{ itemC.country.name
                  }}<span
                    v-if="
                      index >= 0 &&
                      index != prize_candidate.candidate.countries.length - 1
                    "
                    >,&nbsp;</span
                  >
                </p>
              </div>
            </a>
          </div>
        </div>
        <div class="lg:w-1/4 space-y-6 lg:pt-6">
          <div
            v-for="(prize_candidate, index) in finalResult.prize_candidates"
            :key="index"
          >
            <a
              v-if="prize_candidate.is_main_prize === false"
              :href="prize_candidate.candidate.url"
              class="flex"
            >
              <img
                :src="prize_candidate.candidate.image"
                class="object-cover w-16 h-16"
                alt="candidate.image"
              />
              <div class="pl-3.5">
                <p
                  class="font-bold text-sm normal-case md:text-base lg:text-2xl"
                >
                  {{ prize_candidate.candidate.title }}
                </p>
                <p class="font-bold text-primary-500 leading-4">
                  {{ prize_candidate.title.title }}
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment"
import {appAxios} from "@/js/app_axios";

export default {
  name: "final-result",
  props: {
    apiRoute: null,
  },
  data() {
    return {
      locale: this.$store.state.languageCode === 'zh-hans' ? 'zh_cn' : this.$store.state.languageCode,
      finalResult: null
    }
  },
  async beforeCreate() {
    const { data } = await appAxios.get(this.apiRoute)
    if (data.count > 0) {
      this.finalResult = data.results[0]
      this.finalResult.date = moment(this.finalResult.date).locale(this.locale).format("LLL")
    }
  },
  computed: {
    mainPrizes() {
      if (this.finalResult) {
        return this.finalResult.prize_candidates.filter(
          (prize) => prize.is_main_prize === true
        )
      }
      return null
    }
  }
}
</script>

<style lang="scss"></style>

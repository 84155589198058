<template>
  <div id="modalVote" class="modal">
    <div class="modal-content">
      <div
        class="flex justify-between border-b border-gray-300 p-4 md:px-12 py-6"
      >
        <p v-if="successfulVote" class="font-bold text-xl md:text-3xl">
          {{ $store.state.transifex.vote.congratulations }}
        </p>
        <div v-else class="flex items-center">
          <img
            src="@/assets/votes.svg"
            class="w-10 h-10 flex-shrink-0 mr-3.5"
            alt="votes.svg"
          />
          <p class="font-bold text-xl md:text-3xl">
            {{ $store.state.transifex.vote.formTitle }}
          </p>
        </div>
        <span class="close-modal">
          <img
            src="@/assets/cross.svg"
            class="w-7 h-7 flex-shrink-0"
            alt="cross.svg"
          />
        </span>
      </div>
      <div v-if="candidate">
        <div v-if="successfulVote" class="p-4 md:px-12 md:py-8">
          <div class="flex flex-col items-center gap-4 md:flex-row md:gap-8">
            <img
              :src="votedCandidate.image"
              :alt="candidate.title"
              class="w-48 h-auto"
            />
            <div>
              <p>
                {{ $store.state.transifex.vote.votedFor }}
                <span class="font-bold">{{ votedCandidate.title }}</span>
              </p>
              <p class="font-bold">{{ $store.state.transifex.vote.thanks }}</p>
              <p>{{ $store.state.transifex.vote.nextVoting }}</p>
              <p id="countdown-vote-modal"></p>
            </div>
          </div>
        </div>
        <!-- Successful Vote --->

        <div v-else>
          <div
            class="flex flex-col gap-4 border-b border-gray-300 p-4 md:flex-row md:gap-8 md:px-12 md:py-8"
          >
            <img
              :src="candidate.image"
              :alt="candidate.title"
              class="w-48 h-auto"
            />
            <div>
              <p
                class="text-sm font-bold md:text-base lg:text-2xl xl:pb-3"
              >
                {{ candidate.title }}
              </p>
              <p v-if="candidate.age" class="font-bold leading-5">
                {{ candidate.age }} {{ $store.state.transifex.common.yearsOld }}
              </p>
              <div>
                <p
                  v-for="(item, index) in candidate.countries.countries"
                  :key="index"
                  class="leading-5 inline-block"
                >
                  {{ item.country.name
                  }}<span
                    v-if="
                      index >= 0 &&
                      index != candidate.countries.countries.length - 1
                    "
                    >,&nbsp;</span
                  >
                </p>
              </div>
            </div>
          </div>
          <!-- Candidate Info --->

          <form
            id="formVote"
            @submit.prevent="submitVote"
            class="p-4 md:px-12 md:py-8"
          >
            <div v-if="errorMessage" class="pb-4 text-red-500" role="alert">
              {{ errorMessage }}
            </div>
            <!-- Error message --->
            <div>
              <label>{{ $store.state.transifex.vote.firstName }}</label>
              <input
                :placeholder="$store.state.transifex.vote.firstNamePlaceholder"
                type="text"
                id="inputFirstName"
                required="required"
                :value="voterFirstName"
                oninvalid="this.setCustomValidity('Please Enter your first name')"
                oninput="setCustomValidity('')"
                class="input"
              />
            </div>

            <div>
              <label>{{ $store.state.transifex.vote.lastName }}</label>
              <input
                :placeholder="$store.state.transifex.vote.lastNamePlaceholder"
                type="text"
                id="inputLastName"
                required="required"
                :value="voterLastName"
                oninvalid="this.setCustomValidity('Please Enter your first name')"
                oninput="setCustomValidity('')"
                class="input"
              />
            </div>

            <div class="mb-2">
              <label>{{ $store.state.transifex.vote.email }}</label>
              <input
                :placeholder="$store.state.transifex.vote.emailPlaceholder"
                type="email"
                id="inputEmail"
                aria-describedby="emailHelp"
                required="required"
                :value="voterEmail"
                oninvalid="this.setCustomValidity('Please Enter valid email')"
                oninput="setCustomValidity('')"
                class="input"
              />
            </div>

            <div class="flex items-start my-3">
              <input
                type="checkbox"
                id="checkBox"
                class="w-4 mt-1.5 mr-1.5 flex-shrink-0"
              />
              <label
                class="text-base font-light"
                v-html="$store.state.transifex.vote.newsletter"
              >
              </label>
            </div>

            <div
              id="authentification"
              v-html="$store.state.transifex.vote.recaptchaTerms"
            ></div>

            <!--            <vue-recaptcha ref="recaptcha"-->
            <!--               @verify="onCaptchaVerified"-->
            <!--               @expired="onCaptchaExpired"-->
            <!--               size="invisible"-->
            <!--               sitekey="6Lexib8UAAAAADFaASxWyjQUjjWBfiWKPtg7fYmb"-->
            <!--               class="mb-6">-->
            <!--            </vue-recaptcha>-->
            <button type="submit" class="btn-primary w-full py-3">
              {{ $store.state.transifex.vote.submitVote }}
            </button>
          </form>
        </div>
        <!-- Vote template --->
      </div>
    </div>
  </div>
</template>

<script>
import { load } from "recaptcha-v3"
import { appUtils } from "@/js/utils"
import moment from "moment"
import {formatNewsletterSchema} from "@/js/newsletter_schema";
import { GTMManager } from "@/js/gtm_manager"
import {appAxios} from "@/js/app_axios";

export default {
  name: "vote-modal",
  props: {
    candidate: null,
    poll: null,
    apiRegisterVote: null
  },
  data() {
    return {
      errorMessage: null,
      successfulVote: false,
      votedCandidate: null,
      gtm: new GTMManager(),
      voterEmail: this.getVoterInfor()
        ? this.getVoterInfor().voter_email
        : null,
      voterFirstName: this.getVoterInfor()
        ? this.getVoterInfor().first_name
        : null,
      voterLastName: this.getVoterInfor()
        ? this.getVoterInfor().last_name
        : null,
      recaptchaToken: null
    }
  },
  methods: {
    callbackAt0() {
      console.log("vote available now !")
    },
    async loadRecaptcha() {
      const recaptcha = await load("6Lfvo18hAAAAAFojj1gisGts5eRIKg0Ik0ewZvVl", {
        useRecaptchaNet: true
      })
      this.recaptchaToken = await recaptcha.execute("submit_vote")
    },
    async submitVote() {
      await this.loadRecaptcha()
      this.errorMessage = null
      if (this.checkValidityInput()) {
        const csrftoken = document.querySelector(
            "[name=csrfmiddlewaretoken]"
        ).value
        let headers = {"X-CSRFToken": csrftoken}

        let postData = {
          candidate_id: this.candidate.id,
          poll_id: this.poll.id,
          instrument_id: this.poll.instrument.id,
          voter_email: document.getElementById("inputEmail").value,
          first_name: document.getElementById("inputFirstName").value,
          last_name: document.getElementById("inputLastName").value,
          optin_newsletter: document.getElementById("checkBox").checked,
          voting_date: moment.tz().format(),
          vid: this.getVID(),
          recaptchaToken: this.recaptchaToken
        }

        window.localStorage.setItem(
            "voter_info",
            JSON.stringify({
              first_name: postData.first_name,
              last_name: postData.last_name,
              voter_email: postData.voter_email
            })
        )

        try {
          await appAxios.post(this.apiRegisterVote, postData, {headers})
          this.successfulVote = true
          this.setVotedCandidate(this.candidate)
          let now = moment.tz().format()
          appUtils.countdown(
              moment(now).add(1, "d"),
              "countdown-vote-modal",
              this.$store.state.transifex,
              this.callbackAt0,
              true
          )
        } catch (error) {
          console.log(error.response)
          if (error.response.data.error_code === "waiting_expired_vote") {
            this.errorMessage =
                error.response.data.message +
                " " +
                moment(error.response.data.expired_date).format("LLL")
          } else {
            this.errorMessage =
                error.response.data.message ||
                "An error has occurred. Please try again later."
          }
        }

        if (postData.optin_newsletter) {
          try {
            const NewsletterApi = "https://api.medici.tv/satie/newsletter/"
            let newsletterData = formatNewsletterSchema(postData.voter_email, true, this.$store.state.languageCode)
            await appAxios.post(NewsletterApi, newsletterData)
            localStorage.setItem("Leeds2024MedicitvNewsLetter", "True")
            this.gtm.pushGTM("newsletter_success")
          } catch (error) {
            this.gtm.pushGTM("newsletter_error")
            console.error('newsletter_error: ' + error)
          }
        }
      }
    },
    getCookie(name) {
      let cookieValue = null
      if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";")
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim()
          // Does this cookie string begin with the name we want?
          if (cookie.substring(0, name.length + 1) === name + "=") {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
            break
          }
        }
      }
      return cookieValue
    },
    checkValidityInput: function () {
      let inputFirstName = document.getElementById("inputFirstName")
      let inputLastName = document.getElementById("inputLastName")
      let inputEmail = document.getElementById("inputEmail")
      return (
        inputFirstName.checkValidity() &&
        inputLastName.checkValidity() &&
        inputEmail.checkValidity()
      )
    },
    getVID() {
      let vid = window.localStorage.getItem("vid_leeds_2024")
      if (!vid) {
        vid = (Math.random().toString(36) + "00000000000000000").slice(2, 12)
        window.localStorage.setItem("vid_leeds_2024", vid)
      }
      return vid
    },
    getVotedCandidate() {
      let candidate = window.localStorage.getItem("voted_leeds_2024")
      if (candidate) return JSON.parse(candidate)
      return null
    },
    setVotedCandidate(candidate) {
      this.votedCandidate = candidate
      this.votedCandidate["voting_date"] = moment.tz().format()
      window.localStorage.setItem("voted_leeds_2024", JSON.stringify(candidate))
    },
    getVoterInfor() {
      let voterInfor = window.localStorage.getItem("voter_info")
      if (voterInfor) return JSON.parse(voterInfor)
      return null
    }
  },
  mounted() {
    if (this.getVotedCandidate()) {
      this.votedCandidate = this.getVotedCandidate()
      if (this.votedCandidate) {
        let available_date = moment(this.votedCandidate.voting_date).add(1, "d")
        let isAfterNow = available_date.isAfter(moment.tz().format())
        if (isAfterNow) {
          appUtils.countdown(
            available_date,
            "countdown-vote-modal",
            this.$store.state.transifex,
            this.callbackAt0,
            true
          )
          this.successfulVote = true
        } else {
          this.votedCandidate = null
          this.successfulVote = false
        }
      }
    }
    this.loadRecaptcha()
    this.errorMessage = null
    // Get the modal
    let modal = document.getElementById("modalVote")

    // Get the <span> element that closes the modal
    let closeModal = document.getElementsByClassName("close-modal")[0]

    const self = this
    // When the user clicks on <span> (x), close the modal
    closeModal.onclick = function () {
      self.errorMessage = null
      modal.style.display = "none"
    }
    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
      if (event.target == modal) {
        self.errorMessage = null
        modal.style.display = "none"
      }
    }
  }
}
</script>

<style lang="scss">
/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 25; /* Sit on top */
  padding-top: 90px;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 90%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;

  @media only screen and (min-width: 1024px) {
    width: 60%;
  }
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* The Close Button */
.close-modal {
  color: black;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-modal:hover,
.close-modal:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  //background-color: #5cb85c;
  //color: white;
}

.modal-body {
  padding: 2px 16px;
}

.modal-footer {
  padding: 2px 16px;
  //background-color: #5cb85c;
  //color: white;
}
</style>

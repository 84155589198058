<template>
  <div v-if="performance">
    <PlayerReplay
        :performance="performance"
    />
  </div>
  <!--  <pre>{{performance}}</pre>-->
</template>

<script>
import PlayerReplay from "@/components/player/PlayerReplay"
import {appAxios} from "@/js/app_axios";

export default {
  name: "performance-embed-performance",
  props: {
    apiRoute: null,
  },
  components: {
    PlayerReplay
  },
  data() {
    return {
      performance: null
    }
  },
  async beforeCreate() {
    const { data } = await appAxios.get(this.apiRoute)
    if (data.performance?.id) {
      this.performance = data.performance
    }
  },
  mounted() {}
}
</script>

<style lang="scss"></style>

<template>
  <div v-if="performance">
    <p
      class="pb-2 text-xl text-neutral-700 font-bold underline underline-offset-[12px] decoration-2"
    >
      {{ $store.state.transifex.playerChapter.program }}
    </p>

    <div
      class="relative myScrollbar overflow-auto h-64 mt-6 bg-white lg:h-[calc(100%-56px)]"
    >
      <div class="absolute inset-0 text-neutral-700  px-6 py-4">
        <!-- Current performance title -->
        <div class="text-lg font-bold mb-4">{{ performance.title }}</div>

        <!-- Current performance work movements -->
        <div v-for="(item, index) in performance.works_movements" :key="index">
          <!-- Artist -->
          <p
            v-if="item.artists"
            class="text-base font-bold text-primary-400 mb-1"
          >
            {{ item.artists }}
          </p>

          <!-- Work -->
          <div v-if="item.musical_work_movement.length === 0">
            <p
              v-if="item.musical_work"
              class="mb-1 chapter-element relative cursor-pointer"
              :class="{
                'cursor-pointer hover:underline hover:decoration-primary-400':
                  item.musical_work.timecode,
                active: isCurrentMovement(
                  item.musical_work.timecode,
                  playingTime
                )
              }"
              @click="playerSeek(item.musical_work.timecode)"
            >
              <span>{{ item.musical_work.title }}</span>
            </p>
          </div>
          <div v-else>
            <p v-if="item.musical_work"
               class="mb-1 chapter-element relative">
              <span>{{ item.musical_work.title }}</span>
            </p>
          </div>

          <!-- Work's Movements -->
          <ul class="mb-3">
            <li
              v-for="(itemX, index) in item.musical_work_movement"
              :key="index"
              @click="playerSeek(itemX.timecode)"
              class="pl-4 mb-1 chapter-element relative cursor-pointer"
              :class="{
                'cursor-pointer hover:underline hover:decoration-primary-400':
                  itemX.timecode,
                active: isCurrentMovement(itemX.timecode, playingTime)
              }"
            >
              <span>{{ itemX.title }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "player-replay-chapter",
  components: {},
  props: {
    performance: null
  },
  data() {
    return {
      id: "",
      timeCodeList: this.getTimeCodeList()
    }
  },
  computed: {
    playingTime() {
      return this.$store.state.playingTime
    }
  },
  mounted() {},
  methods: {
    playerSeek(timeCode) {
      if (timeCode) {
        this.$store.state.playerInstance.seek(parseInt(timeCode))
      }
    },
    getTimeCodeList() {
      let timeCodes = []
      if (this.performance && this.performance.works_movements) {
        for (let i = 0; i < this.performance.works_movements.length; i++) {
          timeCodes.push(
            this.performance.works_movements[i].musical_work.timecode
          )
          if (this.performance.works_movements[i].musical_work_movement) {
            for (
              let j = 0;
              j <
              this.performance.works_movements[i].musical_work_movement.length;
              j++
            ) {
              timeCodes.push(
                this.performance.works_movements[i].musical_work_movement[j]
                  .timecode
              )
            }
          }
        }
      }
      return [...new Set(timeCodes)]
    },
    isCurrentMovement(timeCode, playingTime) {
      if (this.timeCodeList[0] === null) return false
      let lastTimeCode = this.timeCodeList[0]
      let nextTimeCode = this.timeCodeList[this.timeCodeList.length - 1]
      for (let i = 0; i < this.timeCodeList.length; i++) {
        if (timeCode > this.timeCodeList[i]) {
          lastTimeCode = this.timeCodeList[i]
        }
        if (timeCode < this.timeCodeList[i]) {
          nextTimeCode = this.timeCodeList[i]
          break
        }
      }
      if (
        playingTime >= timeCode &&
        timeCode === this.timeCodeList[this.timeCodeList.length - 1]
      )
        return true
      if (playingTime >= nextTimeCode) return false
      return (
        playingTime >= timeCode &&
        nextTimeCode > timeCode &&
        lastTimeCode <= timeCode
      )
    }
  }
}
</script>

<style lang="scss">
.chapter-element {
  &.active {
    span {
      position: relative;
      display: inline-block;

      &::before {
        position: absolute;
        top: 4px;
        left: -14px;
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        // inline primary-400
        border-left: solid 10px #11aeca;
        content: "";
      }
      
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        // inline primary-400
        background-color: #11aeca;
        content: "";
      }
    }
  }
}
</style>

<template>
  <iframe :src=liveChatUrl
          width="100%" height="450" allowtransparency="yes" allow="autoplay"
          frameborder="0" marginheight="0" marginwidth="0" scrolling="auto">
  </iframe>
</template>

<script>
export default {
  name: "MovieLiveChat",
  props: {
    liveChatCode: null,
    liveChatId: null,
  },
  data() {
    return {
      liveChatUrl: "https://www5.cbox.ws/box/?boxid=937703&boxtag=Z7qdg6&tid=" + this.liveChatId + "&tkey=" + this.liveChatCode
    }
  },
}
</script>

<style lang="scss"></style>

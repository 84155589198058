<template>
  <div v-if="socialLink.length" class="flex items-center space-x-3">
    <p class="text-sm font-bold">Follow on :</p>
    <div
      v-for="(item, index) in socialLink"
      :key="index"
      class="text-primary-500"
    >
      <a :href="item.url" class="hover:underline" target="_blank">
        <span v-if="item.socialmedia" class="text-2xl">
          <i :class="item.socialmedia.icon_class" aria-hidden="true"></i>
        </span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "social-links",
  props: {
    socialLink: null
  }
}
</script>

<style lang="scss"></style>

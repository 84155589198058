<template>
  <div
    v-if="pollActive"
    class="p-6 mt-10 gradient-background"
    :class="{ 'mt-4 md:mt-8': currentLive }"
  >
      <div class="text-2-5xl text-white font-bold  text-center">
        <span v-html="$store.state.transifex.vote.audiencePrize"></span>
      </div>

      <div
        class="mt-4 text-xl text-white font-bold text-center"
      >
        <span v-html="$store.state.transifex.vote.chooseFinalist"></span>
      </div>

      <div class="mt-8 text-center">
        <a
          :href="pageVoteUrl"
          target="_blank"
          class="btn-secondary"
          >{{ $store.state.transifex.vote.voteNow }}</a
        >
      </div>
  </div>
</template>

<script>
export default {
  name: "banner-vote",
  props: {
    pollActive: null,
    currentLive: null,
  },
  data() {
    return {
      pageVoteUrl: '/' + this.$store.state.languageCode + '/vote/',
    }
  },
}
</script>

<style lang="scss"></style>

<template>
  <div class="myContainer pt-7 md:pt-10">
    <h1 class="block max-w-2xl" >{{ $store.state.transifex.competitionTitle.title }}</h1>
  </div>
</template>

<script>
  export default {
    name: "competition-title",
    props: {
    }
  }
</script>
